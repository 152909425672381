// Here you can add other styles
.sidebar {
  background-color: #2b3f67;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e0eee9;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(112, 112, 112);
}
.sidebar-nav .nav-link {
  color: rgb(255 255 255 / 82%);
}

.righthorizontal {
  display: flex;
  flex-direction: row;
}
.logout-cursor {
  cursor: pointer;
}

.hindi_slogan_first {
  position: absolute;
  left: 30px;
  color: #e55353;
}
.hindi_slogan_second {
  color: #e55353;
}

.table-middle {
  vertical-align: middle;
}


.login_page {
  width: 100% !important;
}
.btn-outline-success {
  background-color: #ef9b0f !important;
  border-color: #ef9b0f;
  color: whitesmoke;
}
.btn-outline-success:hover {
  border-color: #ef9b0f;
  color: whitesmoke;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  line-height: 7px;
  margin-bottom: 100px;
}

.navbar {
  display: flex;
  flex-direction: column;
}

.header_form {
  border: 1px solid red;
  padding: 10px;
  border-radius: 15px;
  width: 90%;
}

.header_focus {
  background-color: #8f2886;
  color: white;
  border-radius: 10px;
  padding: 3px;
}
.quotation {
  background: brown;
  color: white;
  padding: 20px;
  border-radius: 88%;
}

thead tr {
  background-color: brown;
  color: white;
}

.lift_without_lift {
  margin-right: 45px;
}

.charge_color {
  color: brown;
}


// print media code
@media print {
  @page {
    size: A4; /* DIN A4 standard, Europe */
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
  }
  body {
    margin: 0;
    box-shadow: 0;
  }
  .kashmir{
    margin-top: 30px;
  }
  .table_label {
    background-color: brown;
    color: white;
    -webkit-print-color-adjust: exact;
    font-size: 10px;
  }
  .table_description {
    font-size: 10px;
  }
  .quotation{
    background: brown;
    color: white;
    padding: 10px;
    border-radius: 88%;
    -webkit-print-color-adjust: exact;

  }
  .header_focus {
    background-color: #8f2886;
    color: white;
    border-radius: 10px;
    -webkit-print-color-adjust: exact;
    padding: 3px;
  }
  // .sherawali{
  //   display: inline-block;
  // }
.packers_emails{
// display: inline;
// width: 100%;
-webkit-print-color-adjust: exact;

  }
  #gstin_id p {
    background: brown;
    color: white;
    padding: 10px;
    border-radius: 88%;
    font-size: 9px;
    // margin-right: 120px m !important;
    -webkit-print-color-adjust: exact;
  }  

  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
}
